import {
  useFindRmRecs,
  useFindRmRecsUpload,
  useRateUpload,
} from '../hooks/useRmRecs';

import { Dialog } from '@headlessui/react';
import { MdCloudUpload } from 'react-icons/md';
import Modal from '../components/Modal';
import PopOutButton from '../components/PopOutButton';
import { RmRec } from '@/graphql/types';
import { RmRecStatus } from '../types/__generated__/globalTypes';
import Table from '../components/Table';
import { baseFormat } from '../renderers/baseFormat';
import dayjs from 'dayjs';
import { displayDate } from '../helpers/dateHelpers';
import { isValidRate } from '../helpers/rateShopHelpers';
import { today } from '../helpers/dateHelpers';
import { useDashboard } from '../context/dashboardContext';
import { useState } from 'react';
import { useUser } from '../context/userContext';

const RateUploadBtn = () => {
  const { oktaUser } = useUser();
  const { brandCode, hotel } = useDashboard();
  const [openModal, setOpenModal] = useState(false);

  const rmRecs = useFindRmRecs({
    filters: {
      hotelId: hotel?.hotel_id || hotel?.brand_code,
    },
  });

  const findRecs = useFindRmRecsUpload({
    filters: {
      hotelId: hotel?.hotel_id || hotel?.brand_code,
      status: RmRecStatus.ACCEPTED,
      hidden: false,
      startDate: today(),
      endDate: undefined,
    },
  });

  const filterHasRate = (rec: RmRec) => {
    return rec.rate !== null && rec.rate !== 0 && rec.rate !== undefined;
  };

  const sendRecs = useRateUpload();

  const pollForRmRecUpdates = () => {
    // After we submit rates for uploading, lets start polling for RM Recs
    // so that once they update the Overview will show the updated status
    rmRecs.startPolling(5000);

    // We will only run polling for 5 minutes, every 5 seconds
    setTimeout(() => rmRecs.stopPolling(), 300000);
  };

  const handleRateUploadSubmit = () => {
    const recs: RmRec[] = findRecs[1].data?.hotelRmRecs;
    setOpenModal(false);
    if (recs) {
      const recInputs = recs.filter(filterHasRate).map((r) => {
        return { id: r.id };
      });

      sendRecs[0]({
        variables: {
          inputs: recInputs,
          brandCode,
          userName: oktaUser?.login_ID,
          userEmail: oktaUser?.email?.toLowerCase(),
        },
        onCompleted: () => pollForRmRecUpdates(),
      });
    }
  };

  const dataAcptRecs = () => {
    const recs: RmRec[] = findRecs[1].data?.hotelRmRecs;
    const invalidRowObj = {
      value: '',
      extraClasses: 'text-red-500 line-through',
    };
    if (recs) {
      const headers = ['Date', 'Current', 'New Rate', 'Status'];
      const sortedRecs = [...recs].sort(function (a, b) {
        return dayjs(a.stay_date).isAfter(dayjs(b.stay_date)) ? 1 : -1;
      });
      const rows = sortedRecs
        .filter((rec) => rec.rate !== null && rec.rate !== 0)
        .map((r) => {
          if (isValidRate(r.rate as number)) {
            return [
              displayDate(r.stay_date),
              baseFormat('rm_recs.rate', r.current_rate, ''),
              baseFormat('rm_recs.rate', r.rate, ''),
              r.status,
            ];
          } else {
            return [
              { ...invalidRowObj, value: displayDate(r.stay_date) },
              {
                ...invalidRowObj,
                value: baseFormat('rm_recs.rate', r.current_rate, ''),
              },
              {
                ...invalidRowObj,
                value: baseFormat('rm_recs.rate', r.rate, ''),
              },
              { ...invalidRowObj, value: r.status },
            ];
          }
        });
      return { headers, rows, perPage: 10 };
    } else {
      return { headers: [], rows: [] };
    }
  };

  const ModalContent = () => {
    const tableProps = dataAcptRecs();

    return (
      <>
        <div>
          <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
            <MdCloudUpload
              className='h-6 w-6 text-green-600'
              aria-hidden='true'
            />
          </div>
          <div className='mt-3 text-center sm:mt-5'>
            <Dialog.Title
              as='h3'
              className='text-lg leading-6 font-medium text-gray-900'
            >
              Confirm Rate Upload
            </Dialog.Title>
            <div className='mt-2'>
              <p className='text-sm text-gray-800'>
                These are the Accepted RM Recs to be uploaded for rate changes.
              </p>
              <p className='text-sm text-gray-800'>
                Please verify before confirming the upload.
              </p>
              <div className='mt-2'>
                {findRecs[1].loading ? (
                  <p className='text-green-500'>Fetching accepted recs...</p>
                ) : tableProps.rows.length > 0 ? (
                  <Table {...tableProps} />
                ) : (
                  <h4 className='text-green-500'>
                    No accepted recs to upload.
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
          <button
            disabled={!tableProps.rows.length}
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm'
            onClick={() => handleRateUploadSubmit()}
          >
            Confirm Upload
          </button>
          <button
            type='button'
            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <PopOutButton
        icon={MdCloudUpload}
        openModal={setOpenModal}
        onClick={findRecs[0]}
      />
      <Modal
        content={<ModalContent />}
        open={openModal}
        setOpen={setOpenModal}
      />
    </>
  );
};

export default RateUploadBtn;
